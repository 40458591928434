.OtherProjects-about-section__container {
  padding: 0 20px 20px 20px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.OtherProjects-about-section__content {
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  overflow: hidden;
}

.OtherProjects-about-section__content--right {
  border-right: 1px solid var(--main-color);
}

.OtherProjects-about-section__content--left {
  border-left: 1px solid var(--main-color);
}

.OtherProjects-about-section__page-title {
  text-align: right;
  padding-bottom: 20px;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
}

.OtherProjects-about-section__page-title--right {
  text-align: right;
}

.OtherProjects-about-section__page-title--left {
  text-align: left;
}

.OtherProjects-about-section__text {
  font-size: 1.8rem;
  font-weight: 300;
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

.OtherProjects-about-section__text--left {
  text-align: right;
  transform: translateX(-200%);
}

.OtherProjects-about-section__text--right {
  text-align: left;
  transform: translateX(200%);
}

.OtherProjects-about-section__text.active {
  transform: translateX(0%);
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

@media (max-width: 400px) {
  .OtherProjects-about-section__text {
    font-size: 1.6rem;
  }

  .OtherProjects-about-section__content {
    padding: 15px;
  }

  .OtherProjects-about-section__container {
    padding: 50px 15px;
  }
}
