.careerJobOffers__Sectionheadline {
  margin: 30px 0px 60px 0px;
  text-align: center;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  overflow-x: unset;
}

.careerJobOffers__container {
  max-width: var(--container-max-width);
  margin: auto;
}

.careerJobOffers__content {
  max-width: 800px;
  margin: 0px auto;
  overflow: hidden;
  padding: 15px 30px;
}

.careerJobOffers__content--blue {
  background-color: var(--main-color);
  color: var(--white-color);
  padding: 30px 30px;
}

.careerJobOffers__headline {
}

.text-color-lingua {
  color: #2f3276;
}

.careerJobOffers__list {
  overflow-x: unset;
  padding-left: 40px;
}

.careerJobOffers__list > li {
  overflow-x: unset;
}

.careerJobOffers__GetInTouchContainer {
  max-width: 800px;
  margin: 50px auto;
  padding: 0 30px 30px 30px;
  overflow: hidden;
}

.careerJobOffers__GetInTouch {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-align: center;
}

.careerJobOffers__SmallText {
  font-size: 14px;
  text-align: center;
}
