.aboutUs-page___container {
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.aboutUs-page___content {
  max-width: 800px;
  margin: 50px auto;
  overflow: hidden;
}

.aboutUs-page__about-text {
  text-align: center;
  margin: 0;
}
