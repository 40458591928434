.contact-section__container {
  padding: 100px;
  background-color: white;
}

.contact-section__page-title {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 500;
  letter-spacing: 0.7px;
  padding-bottom: 30px;
  color: var(--main-color);
}

.contact-section__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.contact-section__form-inner-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-bottom: 20px;
}

.contact-section__input-wrapper {
  display: flex;
  flex-direction: column;
}

.contact-section__input,
.contact-section__textarea {
  width: 320px;
  padding: 16px;
  margin: 20px 0;
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  font-family: "Poppins", sans-serif;
}

.contact-section__file-input {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.contact-section__file-input-label {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #a5a6a7;
  font-size: 16px;
  cursor: pointer;
}

.contact-section__file-input-label svg {
  font-size: 16px;
  color: var(--main-color);
}

.contact-section__filename {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-section__textarea {
  resize: none;
}

@media (max-width: 900px) {
  .contact-section__input,
  .contact-section__textarea {
    width: 100%;
  }

  .contact-section__form-inner-wrapper {
    width: 100%;
  }
  .contact-section__container {
    padding: 50px;
  }
}

@media (max-width: 650px) {
  .contact-section__form-inner-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  .contact-section__textarea {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .contact-section__container {
    /* padding: 50px 20px; */
  }
}
