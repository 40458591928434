.careerDescription__container {
  padding: 50px;
  max-width: var(--container-max-width);
  margin: auto;
}

.careerDescription__content {
  max-width: 800px;
  margin: auto;
  padding: 0;
  overflow: hidden;
}

.careerDescription__text {
  text-align: center;
  margin: 0;
}
