.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  background-color: #03144e;
  padding: 8px 0 12px;
}

.navbar__logo {
  height: 80px;
  width: auto;
  text-decoration: none;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
}

.navbar__list {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.navbar__list-element {
  padding: 2rem 3rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300;
  position: relative;
  cursor: pointer;
}

.navbar__list-element--active {
  font-weight: 700;
}

.navbar__link {
  text-decoration: none;
  color: white;
}

.navbar__link:visited {
  color: white;
}

.navbar__link:hover,
.navbar__list-element:hover {
  color: white;
}

.navbar__hamburger {
  display: none;
}

.navbar__contact-wrapper--mobile {
  display: none;
}

.navbar-dropdown {
  display: flex;
  flex-direction: column;
}

.navbar-dropdown__list {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 83px;
  right: 120px;
  background-color: white;
  color: var(--white-color);
  padding: 14px 20px;
  border-radius: 5px 5px;
}

.navbar__logo-link {
  height: 0px;
}

@media (max-width: 900px) {
  .navbar__list-element {
    margin: 0;
    font-size: 1.5rem;
    margin-right: 10px;
    padding: 10px;
  }
}

@media (max-width: 650px) {
  .navbar {
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    padding: 4px;
  }

  .navbar__main-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar__list-element {
    margin: 1.8rem;
    font-size: 1.5rem;
  }

  .navbar__hamburger {
    display: block;
    background: none;
    outline: none;
    border: none;
    width: 2rem;
    color: #f46036;
    cursor: pointer;
    font-size: 2rem;
    margin-right: 10px;
    padding: 4px 0 0;
  }

  .navbar__menu {
    opacity: 0;
    height: 0;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.1s ease-in-out;
  }

  .navbar__menu--active {
    opacity: 1;
    height: auto;
    transform: translateX(0);
    padding: 1rem;
  }

  .navbar__list {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .navbar__list-element {
    padding: 2px;
    width: 100%;
  }

  .navbar__link {
    display: block;
    width: 100%;
  }

  .navbar__share {
    width: 100%;
    padding: 0;
  }

  .navbar__share-button {
    display: none;
  }

  .navbar__socials {
    display: flex;
    justify-content: center;
    position: static;
  }

  .navbar-dropdown__list {
    position: unset;
    padding-left: 0px;
    width: calc(100% - 44px);
    margin-top: 20px;
  }
}
