.careerCompanyValues__Sectionheadline {
  margin-top: 30px;
  text-align: center;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  overflow-x: unset;
  margin-bottom: 20px;
}

.careerCompanyValues__container {
  padding: 0 50px;
  max-width: var(--container-max-width);
  margin: auto;
}

.careerCompanyValues__content {
  max-width: 600px;
  margin: auto;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.careerCompanyValues__headline {
  margin: 0 auto 20px;
  padding: 12px;
  width: 180px;
  background-color: var(--main-color);
  color: white;
  font-size: 18px;
  border-radius: 48px;
}

.careerCompanyValues__text {
  margin: 20px 0;
}
