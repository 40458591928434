.hero__img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 60px;
}

.hero__headline {
  margin-top: 50px;
  text-align: center;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  overflow-x: unset;
  display: flex;
  justify-content: center;
}

@media (max-width: 650px) {
  .hero__img-container {
    margin-top: 88px;
  }
}

.hero__img-wrapper {
  width: 400px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
}
