.aboutUs-company___container {
  padding: 50px 12px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.aboutUs-company___content {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 800px;
  margin: 50px auto;
}

.aboutUs-company__subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  padding: 12px;
  background-color: var(--main-color);
  color: white;
  font-size: 18px;
  text-align: center;
  width: 180px;
  flex-shrink: 0;
}

.aboutUs-company__headline {
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  text-align: center;
  overflow-x: unset;
}

.aboutUs-company__text {
  text-align: left;
  margin: 20px 0;
}

@media (max-width: 500px) {
  .aboutUs-company__subtitle {
    width: 140px;
    font-size: 14px;;
  }

  .aboutUs-company___content {
    flex-direction: column;
  }
}
