.btn {
  font-family: "Ubuntu", sans-serif;
  padding: 9px 30px;
  font-size: 20px;
  border-radius: 41px;
  cursor: pointer;
}

.transparent-btn {
  background-color: rgba(15, 47, 78, 0.7);
  color: var(--white-color);
  border: 1px solid;
}

.transparent-btn:hover {
  color: #fff;
}

.coloured-btn {
  background-color: #f46036;
  color: #fff;
  border: 3px solid transparent;
  font-weight: 400;
  font-size: 20px;
  padding: 9px 30px;
}

.coloured-btn:hover {
  background-color: transparent;
  background-image: none;
  border: 3px solid var(--main-color);
  color: var(--main-color);
}

.hover-white:hover {
  background-color: white;
}

.shadow-btn {
  margin-bottom: 10px;
  box-shadow: 0px 4px 7px 0px rgba(12, 48, 77, 0.2);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
