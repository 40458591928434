.modal__container.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgb(256, 256, 256, 0.7);
    transition: .2s;
}

.modal__container {
    display: none;
    transition: .2s;
}

.modal__closing-div {
    width: 100%;
    height: 100%;
}

.modal__main-wrapper {
    max-width: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--third-color);
    padding: 100px 70px;
    border: 1px solid #b1b0b0 ;
    /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
    border-radius: 5px 5px;
}

.modal__content {
    font-size: 3rem;
    text-align: center;
    color: var(--main-color);
}